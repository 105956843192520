<template>
  <DisplayView :cardId="cardId" @click="withButtonPressed" @withButtonPressed="withButtonPressed" @withValue="withValue"></DisplayView>
</template>

<script>
import DisplayView from './RemoveCardDisplayView.vue';
export default {
  name: 'remove-card-display-row',
  components: {
    DisplayView
  },
  props: {
    cardId: { type: String, default: "" },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
