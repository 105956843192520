<template>
  <Box>

    <Row v-if="isAdmin">
      <Column :width="15">
        <div class="rc-text-centre">
        Admin View - Billing
        </div>
      </Column>
    </Row>
    <BillingUpdateRow v-if="this.showDialog == 'updateSettingsDialog'" :settingsId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></BillingUpdateRow>
    <CardUpdateRow v-if="this.showDialog == 'updateCardDialog'" :cardId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></CardUpdateRow>
    <RemoveCardDisplayRow v-if="this.showDialog == 'removeCardDialog'" :cardId="this.selectedId" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></RemoveCardDisplayRow>
    <WorkingDisplayRow v-if="this.showDialog == 'workingDialog'" v-on:withButtonPressed="withButtonPressed" v-on:withValue="withValue"></WorkingDisplayRow>

    <Row>
      <Column :width="15">
        <Box :key="redraw">
          <Row 
            v-for="(row, rowIndex) in viewData" 
            :key="rowIndex" >
            
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                 />

          </Row>
        </Box>
      </Column>
    </Row>

  </Box>
  
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Row from "@/portals/shared/library/box/Row.vue";

import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import DisplayViewModel from './DisplayViewModel.js';
import DisplayViewUtils from './DisplayViewUtils.js';
import BillingUpdateRow from './billing/BillingUpdateRow.vue';
import CardUpdateRow from './card/CardUpdateRow.vue';
import RemoveCardDisplayRow from './card/remove/RemoveCardDisplayRow.vue';

export default {
  name: "billing-display-view",
  components: {
    Column, Box, Row,
    CellFactory,
    BillingUpdateRow,
    CardUpdateRow,
    RemoveCardDisplayRow,
  },
  props: {
  },
  data() {
    return {
      redraw: 1,
      isAdmin: false,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CellFactory: CellFactory,
      
      viewModel: null,
      viewData: [],
      showMissingRequirements: false,

      showDialog: null,

      selectedId: null,

      DisplayViewModel: DisplayViewModel,
      DisplayViewUtils: DisplayViewUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    },
    $route (to, from){
      if (to && from) {
        if (to.query["action"] === "update") {
          this.selectedId = to.query["selectedId"];
          this.showDialog = "updateSettingsDialog"

        } else if (to.query["action"] === "updateCard") {
          this.selectedId = to.query["selectedId"];
          this.showDialog = "updateCardDialog"

        } else if (to.query["action"] === "removeCard") {
          this.selectedId = to.query["selectedId"];
          this.showDialog = "removeCardDialog"

        } else {
          this.showDialog = null;

          if (this.showUpdateBilling) {
            this.showUpdateBilling = false;

          } else if (this.showUpdateCard) {
            this.showUpdateCard = false;

          } else {
            this.reloadPage();
          }
        }
      }
    },
  },
  mounted: function () {
      this.viewModel = new this.DisplayViewModel(this);
      this.viewModel.withStatePageLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.start();
      ConnectionUtils.ensureConnection(this);
    },
    methods: {
      ...mapActions([
                     'AuthStorage_updateCompany',
                     'addListenerAction',
                     'removeListenerAction',
                     'updateDomain',
                     ]),
  
      start: function() {
        ConnectionUtils.ensureCompanyLoaded(this, "billing/display");
        this.viewModel.withStateContentLoading();
        this.viewModel.clearAndBuildTableData();
        this.viewData = this.viewModel.viewData();
        this.paint();
        this.viewModel.loadItems(false);
      },

    
    displayProgress: function(msg) {
      this.callback(msg);
    },

    callback: function(msg) {
      var row = this.viewModel.findRowProgress();
      if (row) {
        row.withMessage(msg);
      }
      this.paint();
    },
    
    model: function() {
      return this.viewModel;
    },
    
    withValue: function(valueData) {
      this.model().withValue(valueData);

      if (valueData["field"] === "PageSize") {
        this.model().withPageSize(valueData, "billing");
        this.addQueryParams(valueData, "pageSize");
      }
      if (valueData["field"] === "PageIndex") {
        this.addQueryParams(valueData, "page");
      }
    },
    
    withButtonPressed: function(buttonData) {
      this.model().withButtonPressed(buttonData);

      if (buttonData["field"] === "DetailsClose") {
        var query = Object.assign({}, this.$route.query);
        query = this.deleteDetails(query);
        this.$router.push({ query });
        if (buttonData["action"] == "reload") {
          this.reloadPageDelayed();
        }
      }

      if (buttonData["field"] === "UpdateButton") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "update";
        query["selectedId"] = this.Contents.baseEncode(buttonData["id"]);
        this.$router.push({ query });
      }

      if (buttonData["field"] === "UpdateCcButton") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "updateCard";
        query["selectedId"] = this.Contents.baseEncode(buttonData["id"]);
        this.$router.push({ query });
      }

      if (buttonData["field"] === "Pdf") {
        //
      }

      if (buttonData["field"] === "RemoveCcButton") {
        const query = Object.assign({}, this.$route.query);
        query["action"] = "removeCard";
        query["selectedId"] = this.Contents.baseEncode(buttonData["id"]);
        this.$router.push({ query });
      }

      if (buttonData["field"] === "Refresh") {
        this.reloadPage();
      }

      if (buttonData["field"] === "NextPage") {
        const query = Object.assign({}, this.$route.query);
        if (query.page) {
          query.page = (Number(query.page) + 1) + "";
        } else {
          query.page = "2";
        }
        if (query.page <= this.model().pages().length) {
          this.$router.push({ query });
        }
      }

      if (buttonData["field"] === "PreviousPage") {
        const query = Object.assign({}, this.$route.query);
        if (query.page) {
          query.page = (Number(query.page) - 1) + "";
          if (query.page <= 0) {
            query.page = "1";
          }
          this.$router.push({ query });
        }
      }
    },
    
    addQueryParams(valueData, key) {
      var query = Object.assign({}, this.$route.query);
      if (query == null) {
        query = {};
      }
      const value = valueData["value"];
      if (value && value != "null") {
        query[key] = value;
        if (key === "pageSize") {
          delete query.page
        }
      } else {
        delete query[key];
      }
      this.$router.push({ query });
    },
    
    deleteDetails(query) {
      delete query.action;
      delete query.selectedId;
      return query;
    },

    reloadPageDelayed() {
      this.model()
        .withStateContentLoading()
        .clearAndBuildTableData()
        .done();
      setTimeout(this.reloadPage, 500);
    },

    reloadPage: function() {
      this.viewModel._list = [];
      this.viewModel.withStateContentLoading();
      this.viewModel.clearAndBuildTableData();
      this.viewModel.loadItems();
    },

    paint: function() {
      this.redraw++;
    },

    complete: function() {
      setTimeout(this.completeImpl, 200);
    },

    completeImpl: function() {
      const action = this.$route.query["action"]
      const id = this.$route.query["selectedId"];
      if (action == "update") {
        this.selectedId = id;
        this.showDialog = "updateSettingsDialog";
      }

      if (action == "updateCard") {
        this.selectedId = id;
        this.showDialog = "updateCardDialog";
      }

      if (action == "removeCard") {
        this.selectedId = id;
        this.showDialog = "removeCardDialog";
      }
    }
  },
}
</script>