
import RcDomainMap from "../../common/RcDomainMap.js";
import Invoice from './Invoice.js';
import InvoiceUtils from "./InvoiceUtils.js";

export default class InvoiceMap extends RcDomainMap {
  static MODEL_NAME = Invoice.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data);
  }

  copy() {
    var data = this._copy();
    return new InvoiceMap(this.domain, data);
  }

  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getInvoice(key);
      values.push(item);
    }
    return values;
  }
  
  getInvoice(key) {
    var value = super.get(key);
    return new Invoice(this.domain, value);
  }

  sort() {
    var list = this.list();
    return list.sort(InvoiceUtils.SortByCreatedDate);
  }

  sortByInvoiceNumber() {
    var list = this.list();
    return list.sort(InvoiceUtils.SortByInvoiceNumber);
  }

  find() {
    var keys = this.keys();
    var found = new InvoiceMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.invoices().findById(key);
      var item = new Invoice(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    return found;
  }
}