import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";

import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class DisplayViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.CU = new ContentUtils();
    this.SU = new StringUtils();
  }

  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      return;
    }
    if (this.model().isStateContentLoading()) {
      return;
    }

    if (!this.model().isStateContentLoaded()) {
      return;
    }

    this.renderHeader();
    this.renderBody();
    this.renderFooter();
  }


  renderHeader() {
    // this.add(new TitleDisplayRow().withTitle("Billing"));
    //
  }

  renderBody() {
    var buttonBoxRow = new RowDisplayRow()
      .withChild(new SpacerDisplayRow().withWidth(11));

    if (this.model().isAdmin()) {
      buttonBoxRow
        .withChild(new ButtonDisplayRow()
          .withName("Remove")
          .withField("RemoveCcButton")
          .withKey("RemoveCcButton")
          .withWidth(2).withId(this.model().settings().card().id()))
    } else {
      buttonBoxRow
        .withChild(new SpacerDisplayRow().withWidth(2));
    }

    buttonBoxRow
      .withChild(new ButtonDisplayRow()
        .withName("Update")
        .withField("UpdateCcButton")
        .withKey("UpdateCcButton")
        .withWidth(2).withId(this.model().settings().card().id()));
    var buttonBox = new BoxDisplayRow().withChild(buttonBoxRow);

    let expiryValue = this.getExpiryValue();
    let expiryHint = this.expiryHint(expiryValue);

    let company = this.model().company();
    var status = null;
    if (company.isPaymentMethodCreditCard()) {
      if (!company.hasCard()) {
        status = new StatusDisplayRow().withLabelWidth(0).withValue("warning")
          .withObject("You must add a Credit Card before your contract start day of " + company.contractStartDate().displayAs_YYYY_MM_DD());
      }
    }

    let cardStatus = new StatusDisplayRow().withLabel("Expiry").withValue(expiryValue).withHint(expiryHint).withObject(this.model().settings().card().find().expiry())
    if (!company.hasCard()) {
      cardStatus = null
    }
    let amex = new StringDisplayRow().withFontSize("small").withValue("We do NOT accept American Express Cards");
    if (company.hasCard()) {
      amex = null;
    }
    
    let leftSideContent = new BoxDisplayRow().withWidth("9").withBorder(true)
      .withChild(new TitleDisplayRow().withTitle("Payment"))
      .withChild(new BoxDisplayRow().withBorder(true)
        .withChild(status)
      )
      .withChild(amex)
      .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().card().find().name()))
      .withChild(new LabelValueDisplayRow().withLabel("Number").withValue(this.model().settings().card().find().number()))
      .withChild(cardStatus)
      .withChild(buttonBox);

    let leftSide = new RowDisplayRow()
      .withChild(new StringDisplayRow().withWidth("3"))
      .withChild(leftSideContent)
      .withChild(new StringDisplayRow().withWidth("3"));

    let rightSideContent = new BoxDisplayRow().withWidth("9").withBorder(true)
      .withChild(new TitleDisplayRow().withTitle("Invoice Details"))
      .withChild(new StringDisplayRow().withValue("This information will appear on your invoice"))
      .withChild(new BoxDisplayRow().withBorder(true)
        .withChild(new TitleDisplayRow().withTitle("Billing Company").withSubtitle(true))
        .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().companyName()))
      )

      .withChild(new BoxDisplayRow().withBorder(true)
        .withChild(new TitleDisplayRow().withTitle("Billing Contact").withSubtitle(true))
        .withChild(new LabelValueDisplayRow().withLabel("Name").withValue(this.model().settings().contactName()))
        .withChild(new LabelValueDisplayRow().withLabel("Email").withValue(this.model().settings().contactEmail()))
        .withChild(new LabelValueDisplayRow().withLabel("Phone").withValue(this.model().settings().contactPhone()))
        .withChild(new LabelValueDisplayRow().withLabel("Address").withValue(this.model().settings().address().find().location()))
      )

      .withChild(new BoxDisplayRow().withBorder(true)
        .withChild(new TitleDisplayRow().withTitle("Invoice Notification Emails and Purchase Order #").withSubtitle(true))
        .withChild(new BoxDisplayRow()
          .withChild(new StringDisplayRow().withValue("Invoices sent via email the first week of the month"))
        )
        .withChild(new LabelValueDisplayRow().withLabel("Email To").withValue(this.model().settings().emailTo()).withHint("A copy the invoice will be sent to these addresses (separate each email address with a comma)."))
        .withChild(new LabelValueDisplayRow().withLabel("PO").withValue(this.model().settings().purchaseOrder()))
      )
      .withChild(new BoxDisplayRow().withChild(new RowDisplayRow()
        .withChild(new SpacerDisplayRow().withWidth(13))
        .withChild(new ButtonDisplayRow().withName("Update").withField("UpdateButton").withKey("UpdateButton")
          .withWidth(2).withId(this.model().settings().id()))
      )
      );

    let rightSide = new RowDisplayRow()
      .withChild(new StringDisplayRow().withWidth("3"))
      .withChild(rightSideContent)
      .withChild(new StringDisplayRow().withWidth("3"));

    if (leftSide && rightSide) {
      this.add(new BoxDisplayRow().withChild(leftSide));
      this.add(new BoxDisplayRow().withChild(rightSide));
    }

    // this.add(new RowDisplayRow().withChild(leftSideContent.withWidth(7)).withChild(rightSideContent.withWidth(8)));

    // this.addPlanInformation(leftSideContent);
    // this.addCreditInformation(leftSideContent);

    this.addPlanInformation();
    this.addCreditInformation();
  }

  addCreditInformation(parentContent) {
    let rows = [];

    rows.push(new LabelValueDisplayRow()
      .withLabel("Overpayment ")
      .withValue("$" + this.model().overpayment().toFixed(2)))

    rows.push(new LabelValueDisplayRow()
      .withLabel("Credit ")
      .withValue("$" + this.model().credit().toFixed(2)))

    let creditRow = new BoxDisplayRow().withWidth("9")
      .withChild(new TitleDisplayRow().withTitle("Overpayment Balance and Credit Information"))
      .withChild(new BoxDisplayRow().withBorder(true)
        .withChildren(rows)
      );

    let planBox = new BoxDisplayRow().withChild(new RowDisplayRow()
      .withChild(new StringDisplayRow().withWidth("3"))
      .withChild(creditRow)
      .withChild(new StringDisplayRow().withWidth("3")));

    if (parentContent) {
      parentContent.withChild(creditRow);
    } else {
      this.add(planBox);
    }
  }

  getExpiryValue() {
    let expiryValue = "Good";
    let now = this.domain().time().now().displayAs_MM_YYYY();
    let nowMonth = now.split(" ")[0];
    let nowYear = now.split(" ")[1];
    let expiryMonth = this.model().settings().card().find().month();
    let expiryYear = this.model().settings().card().find().year();

    if (expiryYear < nowYear) {
      expiryValue = "Expired";
    }
    
    if (expiryYear == nowYear && expiryMonth < nowMonth) {
      expiryValue = "Expired";
    }
    
    if (expiryYear == nowYear && expiryMonth == nowMonth) {
      expiryValue = "ExpireSoon";
    }

    return expiryValue;
  }

  expiryHint(expiryValue) {
    let hint = "";
    if (expiryValue == "Expired") {
      hint = "Expired";
    }

    if (expiryValue == "ExpireSoon") {
      hint = "Expires this month";
    }
    return hint;
  }

  addPlanInformation(parentContent) {
    var rows = [];
    var company = this.model().company();
    var settings = company.invoiceSettings();

    if (this.model().hasFeatureVehicles()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Vehicle Storage Fee")
        .withValue("$" + this.model().priceVehicles() + " per vehicle or trailer per month"))
    }

    if (this.model().hasFeatureInspections()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Vehicle Inspections")
        .withValue("$" + this.model().priceInspections() + " per new Vehicle Inspection"))
    }

    if (this.model().hasFeatureTimesheets()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Hours of Service")
        .withValue("$" + this.model().priceTimesheets() + " per On Duty Day (No charge for days recorded as Off Duty all day)"))
    }

    if (this.model().hasFeatureEquipment()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Equipment Storage Fee")
        .withValue("$" + this.model().priceEquipment() + " per piece of equipment per month"))
    }

    if (this.model().hasFeatureEquipmentInspections()) {
      rows.push(new LabelValueDisplayRow()
        .withLabel("Equipment Inspections")
        .withValue("$" + this.model().priceEquipmentInspections() + " per new Equipment Inspection"))
    }

    rows.push(new LabelValueDisplayRow().withLabel("Charged").withValue(company.billingPeriodDisplay() + " using " + settings.paymentMethodDisplay()))

    let planRow = new BoxDisplayRow().withWidth("9")
      .withChild(new TitleDisplayRow().withTitle("Plan Information"))
      .withChild(new BoxDisplayRow().withBorder(true)
        .withChildren(rows)
      );

    if (parentContent) {
      parentContent.withChild(planRow);
    } else {
      this.add(new BoxDisplayRow().withChild(new RowDisplayRow()
        .withChild(new StringDisplayRow().withWidth("3"))
        .withChild(planRow)
        .withChild(new StringDisplayRow().withWidth("3"))));
        this.add(new SpacerDisplayRow());
    }
  }

  renderFooter() {
    //
  }
}